import ArticlePreview from '@components/article-preview'
import Layout from '@container/layout'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { Col, Container, Pagination, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'

const TagsTemplate = ({ data, pageContext }) => {
  const seo = data.allContentfulGlobalSeo.edges[0].node
  const tagSeoTitleBase =
    data.allContentfulGlobalSeo.edges[0].node.tagListSeoTitle
  const tagSeoTitle = tagSeoTitleBase
    ? `${pageContext.slug} | ${tagSeoTitleBase}`
    : seo.globalSeoTitle
  const tagSeoDescriptionBase = `${data.allContentfulGlobalSeo.edges[0].node.tagListSeoDescription}`
  const tagSeoDescription = tagSeoDescriptionBase
    ? `${pageContext.slug} | ${tagSeoDescriptionBase}`
    : seo.globalSeoDescription
  const posts = data.allContentfulBlogPost.edges
  const {
    limit,
    slug,
    humanPageNumber,
    pageNumber,
    numberOfPages,
    paginationPath,
  } = pageContext
  const numberOfPosts = posts.length

  return (
    <Layout>
      <Helmet title={tagSeoTitle} />
      <header>
        <Container>
          <h2 className="text-white text-center font-weight-bold pt-5">
            {slug}: タグ一覧
          </h2>
          <div className="d-flex justify-content-end">
            <Link to="/" className="text-white mb-0">
              トップページへ戻る
            </Link>
          </div>
          <hr className="mt-0 w-100" />
        </Container>
      </header>
      <Container className="mb-5">
        <Row>
          {posts.map(({ node, index }) => {
            return <ArticlePreview article={node} key={node.slug} />
          })}
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Pagination>
              {[...Array(numberOfPages)].map((_, i) => {
                if (i === 0) {
                  return (
                    <Pagination.Item href={paginationPath}>
                      {i + 1}
                    </Pagination.Item>
                  )
                } else {
                  return (
                    <Pagination.Item href={`${paginationPath}/${i + 1}`}>
                      {i + 1}
                    </Pagination.Item>
                  )
                }
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default TagsTemplate

export const tagListQuery = graphql`
  query tagListQuery($skip: Int!, $limit: Int!, $slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulGlobalSeo {
      edges {
        node {
          globalSeoTitle
          globalSeoDescription
          tagListSeoTitle
          tagListSeoDescription
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [updatedAt], order: DESC }
      limit: $limit
      skip: $skip
      filter: { tag: { elemMatch: { name: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          slug
          createdAt(formatString: "YYYY-MM-DD")
          tag {
            name
          }
          thumbnail {
            fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
